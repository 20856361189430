body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.gmnoprint{
  /* Modify the size of the zoom in icon */
  /* width: 30px !important;
  height: 30px !important; */
  display: none;
}
.gm-fullscreen-control{
  display: none;
}
.fixed {
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 18px #737373;
}
.header_main_fixed {
  padding: 8px 0!important;
}
.header_logo_fixed {
  max-width: 100px;
  
}
body.modal_set:after {
  content: "";
  background: #000000bd;
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
body.no_scroll {
  overflow: hidden;
}
body.modal_set {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'FuturaCyrillicBold';
  src: url('./fonts/FuturaCyrillicBold.eot');
  src: url('./fonts/FuturaCyrillicBold.eot') format('embedded-opentype'),
       url('./fonts/FuturaCyrillicBold.woff2') format('woff2'),
       url('./fonts/FuturaCyrillicBold.woff') format('woff'),
       url('./fonts/FuturaCyrillicBold.ttf') format('truetype'),
       url('./fonts/FuturaCyrillicBold.svg#FuturaCyrillicBold') format('svg');
}
@font-face {
  font-family: 'FuturaCyrillicBook';
  src: url('./fonts//FuturaCyrillicBook.eot');
  src: url('fonts/FuturaCyrillicBook.eot') format('embedded-opentype'),
       url('fonts/FuturaCyrillicBook.woff2') format('woff2'),
       url('fonts/FuturaCyrillicBook.woff') format('woff'),
       url('fonts/FuturaCyrillicBook.ttf') format('truetype'),
       url('fonts/FuturaCyrillicBook.svg#FuturaCyrillicBook') format('svg');
}
@font-face {
  font-family: 'FuturaCyrillicDemi';
  src: url('fonts/FuturaCyrillicDemi.eot');
  src: url('fonts/FuturaCyrillicDemi.eot') format('embedded-opentype'),
       url('fonts/FuturaCyrillicDemi.woff2') format('woff2'),
       url('fonts/FuturaCyrillicDemi.woff') format('woff'),
       url('fonts/FuturaCyrillicDemi.ttf') format('truetype'),
       url('fonts/FuturaCyrillicDemi.svg#FuturaCyrillicDemi') format('svg');
}
@font-face {
  font-family: 'FuturaCyrillicExtraBold';
  src: url('fonts/FuturaCyrillicExtraBold.eot');
  src: url('fonts/FuturaCyrillicExtraBold.eot') format('embedded-opentype'),
       url('fonts/FuturaCyrillicExtraBold.woff2') format('woff2'),
       url('fonts/FuturaCyrillicExtraBold.woff') format('woff'),
       url('fonts/FuturaCyrillicExtraBold.ttf') format('truetype'),
       url('fonts/FuturaCyrillicExtraBold.svg#FuturaCyrillicExtraBold') format('svg');
}
@font-face {
  font-family: 'FuturaCyrillicLight';
  src: url('fonts/FuturaCyrillicLight.eot');
  src: url('fonts/FuturaCyrillicLight.eot') format('embedded-opentype'),
       url('fonts/FuturaCyrillicLight.woff2') format('woff2'),
       url('fonts/FuturaCyrillicLight.woff') format('woff'),
       url('fonts/FuturaCyrillicLight.ttf') format('truetype'),
       url('fonts/FuturaCyrillicLight.svg#FuturaCyrillicLight') format('svg');
}
@font-face {
  font-family: 'FuturaCyrillicMedium';
  src: url('fonts/FuturaCyrillicMedium.eot');
  src: url('fonts/FuturaCyrillicMedium.eot') format('embedded-opentype'),
       url('fonts/FuturaCyrillicMedium.woff2') format('woff2'),
       url('fonts/FuturaCyrillicMedium.woff') format('woff'),
       url('fonts/FuturaCyrillicMedium.ttf') format('truetype'),
       url('fonts/FuturaCyrillicMedium.svg#FuturaCyrillicMedium') format('svg');
}
